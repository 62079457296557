<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="5">
          <el-date-picker
              v-model="form.date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="daterange"
              style="width: 100%"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search" size="medium">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-row>
      <p class="title">{{ dateString }} 培训收款项目分析</p>
      <el-col :span="12">
        <div id="proceedsView"/>
      </el-col>
      <el-col :span="11" :offset="1">
        <el-table
            :data="proceedsData"
            show-summary
            style="width: 80%">
          <el-table-column prop="projectName" label="项目名称" align="center"/>
          <el-table-column prop="sumMoney" label="金额" align="center"/>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {Pie} from "@antv/g2plot";

export default {
  name: "train-proceeds-analysis",
  data() {
    return {
      form: {
        date: this.$dateUtils.getMonthStartEndDayStrArray(),
      },
      dateString: this.$dateUtils.getMonthStartEndDayStr(),
      proceedsData: [],
      proceedsView: {},
    }
  },
  mounted() {
    this.initProceedsView()
    this.queryProcess()
  },
  methods: {
    search() {
      this.dateString=this.$dateUtils.stringArrayToString(this.form.date)
      this.queryProcess()
    },
    initProceedsView() {
      this.proceedsView = new Pie('proceedsView', {
        appendPadding: 1,
        data: this.proceedsData,
        mate: {},
        angleField: 'sumMoney',
        colorField: 'projectName',
        radius: 0.8,
        label: {
          type: 'outer',
          content: '{name} {percentage}',
        },
        interactions: [{type: 'pie-legend-active'}, {type: 'element-active'}],
      })
      this.proceedsView.render();
    },
    queryProcess() {
      this.$axios({
        method: "get",
        url: "/shopReports/queryTrainProcessAnalysis",
        params: {
          date:this.dateString,
        }
      }).then(response => {
        this.proceedsData = response.data.data
        this.proceedsView.changeData(this.proceedsData)
      })
    }
  }
}
</script>

<style scoped>
.title {
  margin: 2% 2%;
  font-size: 30px;
}
</style>